import * as React from 'react';
import config from 'config';

const LoginScreen = React.lazy(() => import('./login-screen'));

const AuthErrorFallback = () => {
  if (config.isNodeProdMode) {
    const currentHref = `${encodeURIComponent(window.location.href)}`;
    const redirectUrl = `${config.loginUrl}?next=${currentHref}`;
    window.location = redirectUrl;
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <LoginScreen />
    </React.Suspense>
  );
};

export default AuthErrorFallback;
