const config = {
  isNodeDevMode: process.env.NODE_ENV === 'development',
  isNodeProdMode: process.env.NODE_ENV === 'production',
  //
  cdnUrl: process.env.REACT_APP_CDN_URL || '',
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  // for dev mode login
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  loginEmail: process.env.REACT_APP_LOGIN_EMAIL,
  loginPassword: process.env.REACT_APP_LOGIN_PASSWORD,
  amplitudeId: process.env.REACT_APP_AMPLITUDE_ID,
};

export const oauthUrls = {
  apple: process.env.REACT_APP_APPLE_OAUTH_URL,
  google: process.env.REACT_APP_GOOGLE_OAUTH_URL,
  facebook: process.env.REACT_APP_FACEBOOK_OAUTH_URL,
};

export const apiUrls = {
  exam: process.env.REACT_APP_EXAM_API_URL,
  auth: process.env.REACT_APP_AUTH_API_URL,
  voicetube: process.env.REACT_APP_VOICETUBE_API_URL,
};

export default config;
