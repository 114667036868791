import { $axios } from './http-client';

export const fetchExams = (params) => $axios
  .get('/toeic/exam', {
    params: {
      perPage: params?.perPage || 20,
      page: params?.page || 1,
    },
  })
  .then((res) => res.data.data);

export const fetchExamById = (examId) => $axios
  .get(`/toeic/exam/${examId}`)
  .then((res) => res.data.data);

export const submitExam = (data) => $axios
  .post('/toeic/examResult', data)
  .then((res) => res.data.data);

export const fetchExamResults = (params) => $axios
  .get('/toeic/examResults', {
    params: {
      perPage: params?.perPage || 10,
      page: params?.page || 1,
      sort: params?.sortBy || '-testedAt',
    },
  })
  .then((res) => res.data.data);

export const fetchExamResultById = (resultId) => $axios
  .get(`/toeic/examResult/${resultId}`)
  .then((res) => res.data.data);
