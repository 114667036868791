import Cookies from 'js-cookie';
import {
  useEffect,
  useContext,
  createContext,
} from 'react';
import { useAsyncFn } from 'react-use';
import * as apis from 'apis';
import config from 'config';
import AuthErrorFallback from './auth-error-fallback';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

const logout = () => {
  const domain = config.isNodeDevMode
    ? 'localhost'
    : '.voicetube.com';
  Cookies.remove('accessToken', { domain, path: '/' });
  window.location = window.location.origin;
};

const AuthProvider = ({ children }) => {
  const [state, fetchProfile] = useAsyncFn(apis.fetchProfile);

  useEffect(() => {
    fetchProfile();
  }, []);

  if (state.error) {
    return <AuthErrorFallback />;
  }

  if (!state.value) {
    return null;
  }

  const profile = state.value;

  return (
    <AuthContext.Provider value={{ profile, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
