/* eslint-disable no-param-reassign */
import axios from 'axios';
import Cookies from 'js-cookie';
import { apiUrls as urls } from 'config';

const $axios = axios.create({
  baseURL: urls.exam,
});

$axios.interceptors.request.use((config) => {
  const token = Cookies.get('accessToken');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export {
  $axios,
  urls,
};
