import config from 'config';
import { urls, $axios } from './http-client';

export const checkToken = () => $axios.get(`${urls.auth}/accessToken`);

export const fetchProfile = () => $axios
  .get(`${urls.auth}/me`)
  .then((res) => res.data.data);

export const createTokenByPassword = (data) => $axios
  .post(`${urls.auth}/oauth/token`, {
    grant_type: 'password',
    client_id: config.clientId,
    client_secret: config.clientSecret,
    username: data.username, // email
    password: data.password,
  })
  .then((res) => res.data.data);
