import {
  mq,
  CssBase,
  WebUiProvider,
} from '@voicetube/web-ui';
import { createGlobalStyle } from 'styled-components';
import AuthProvider from './auth-provider';

const GlobalStyle = createGlobalStyle`
  :root {
    --header-height: 72px;
    ${mq.down('md')} {
      --header-height: 56px;
    }
  }
`;

const RootProviders = ({ children }) => {
  return (
    <WebUiProvider>
      <CssBase />
      <GlobalStyle />
      <AuthProvider>
        {children}
      </AuthProvider>
    </WebUiProvider>
  );
};

export default RootProviders;
