import React from 'react';
import ReactDOM from 'react-dom';
import RootProviders from 'app/root-providers';

const App = React.lazy(() => import('app/app'));

ReactDOM.render(
  <React.StrictMode>
    <RootProviders>
      <React.Suspense fallback={null}>
        <App />
      </React.Suspense>
    </RootProviders>
  </React.StrictMode>,
  document.getElementById('root'),
);
